import React, { useState, useEffect, useRef } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import Tooltip from '@material-ui/core/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { usePurchases } from './shared/purchaseGqlHelper';
import { useDataHelper } from './shared/dataHelper';
import translation from './shared/translation';
import { MainContainer, InnerContainer } from './shared/SponsorPagesContainers';

import {
  // AimPurchasesTable,
  AimPurchasesDatagrid,
  AimIconAndTextButton,
  AimTitleAndButtons,
  CustomIntl,
} from '@aim/components';
import { constants, utilities } from '@aim/common';

const MyPurchases = () => {
  const { sponsorId } = useParams();
  const history = useHistory();
  const { list } = usePurchases();
  const { isValidPurchase, decodeDbNumber, vatCalc, sortBy } = useDataHelper();

  const intl = CustomIntl(useIntl());
  const i18n = translation.sponsorsPurchasesPage(intl);
  const i18nTable = translation.sponsorsPurchasesTable(intl);

  //refs
  const datagridFilteredRowsRef = useRef();

  const [purchases, setPurchases] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await list(sponsorId);

    const physicalStandService = { ...res?.event?.physicalStandService };

    const resFilteredByPaidItems = res?.purchases?.items.filter(
      (item) => item?.buyOperations?.items?.length && isValidPurchase(item)
    );

    const standardPurchases = resFilteredByPaidItems.filter(
      (purchase) =>
        !(
          purchase.buyOperations.items.length === 1 &&
          purchase.buyOperations.items[0].sponsorPackage
        )
    );
    const packagesPurchases = resFilteredByPaidItems
      .filter(
        (purchase) =>
          purchase.buyOperations.items.length === 1 &&
          purchase.buyOperations.items[0].sponsorPackage
      )
      .map((purchase) => ({
        ...purchase,
        packageName: purchase.buyOperations.items[0].sponsorPackage.name,
        buyOperations: {
          items: purchase.buyOperations.items[0].sponsorPackage.services.items
            .map((service) => ({
              ...purchase.buyOperations.items[0],
              ...service,
              unitaryPrice: service.netPrice,
              unitaryVatRate: service.vatRate,
              isPackage: true,
            }))
            .flat(),
        },
      }));

    const finalPurchases = [...standardPurchases, ...packagesPurchases];

    const sortedPurchases = sortBy(finalPurchases, 'createdAt').reverse();

    const formattedPurchases = sortedPurchases.map((item) => {
      let paymentTypeFormat = '';
      switch (item?.paymentType) {
        case constants.PaymentTypes.BankTransfer:
          paymentTypeFormat = i18n.paymentTypes.bankTransfer.label;
          break;
        case constants.PaymentTypes.CreditCard:
          paymentTypeFormat = i18n.paymentTypes.creditCard.label;
          break;
        default:
          break;
      }
      const isVatExent = item?.payment?.BillingInformation?.isVatExent;
      const paidFee = item?.payment?.amount || 0;

      // totalFee: total included Vat
      const totalFee = item?.buyOperations?.items.reduce((prev, curr) => {
        //CUSTOMIZATION FOR ESOT
        if (
          curr.otherSponsorizationsBookingItem ||
          //CUSTOMIZATION FOR GIVRE
          curr?.physicalStandSpaceItems?.item?.id ===
            '9bc674e0-8637-497c-8fa8-dc339baa2d47' ||
          //CUSTOMIZATION FOR SIFO
          curr?.physicalStandSpaceItems?.item?.id ===
            'b5cb28c6-ace8-48d4-972a-f343ca3ca802' ||
          !isVatExent
        ) {
          prev += utilities.vatCalc(
            getUnitaryPrice(curr) * getQuantity(curr),
            getUnitaryVatRate(curr)
          );
        } else if (isVatExent) {
          prev +=
            getUnitaryPrice(curr) * (curr.isPackage ? 1 : getQuantity(curr));
        }
        return prev;
      }, 0);

      const buyOperationsItems = item?.buyOperations.items?.map((op) => {
        let showPrices = true;

        const parsedUnitaryPrice = getUnitaryPrice(op);

        if (
          op?.physicalStandSpace !== null &&
          physicalStandService?.shouldHidePrice &&
          parsedUnitaryPrice ===
            (physicalStandService?.hidePriceWhenPriceIsEqualToAmount || 0)
        ) {
          showPrices = false;
        }

        const quantity = getQuantity(op);

        const parsedUnitaryVatRate =
          //CUSTOMIZATION FOR ESOT
          op.otherSponsorizationsBookingItem ||
          //CUSTOMIZATION FOR GIVRE
          op?.physicalStandSpaceItems?.item?.id ===
            '9bc674e0-8637-497c-8fa8-dc339baa2d47' ||
          //CUSTOMIZATION FOR SIFO
          op?.physicalStandSpaceItems?.item?.id ===
            'b5cb28c6-ace8-48d4-972a-f343ca3ca802' ||
          !isVatExent
            ? decodeDbNumber(op.unitaryVatRate)
            : 0;
        const parsedFinalPrice =
          vatCalc(parsedUnitaryPrice, parsedUnitaryVatRate) *
          (op.isPackage ? 1 : quantity);

        const parsedTotalNetPrice = parsedUnitaryPrice * getQuantity(op);
        const parsedTotalVatAmount = parsedUnitaryVatRate
          ? getUnitaryVatAmount(op, parsedUnitaryVatRate) * getQuantity(op)
          : 0;
        const parsedTotalPrice = utilities.vatCalc(
          parsedUnitaryPrice * getQuantity(op),
          parsedUnitaryVatRate || 0
        );

        return {
          ...op,
          serviceType: getServiceType(op),
          instance: getInstance(op),
          quantity,
          ...(showPrices
            ? {
                parsedUnitaryVatRate,
                parsedUnitaryVatAmount: parsedUnitaryVatRate
                  ? getUnitaryVatAmount(op, parsedUnitaryVatRate)
                  : 0,
                parsedUnitaryPrice: parsedUnitaryPrice,
                parsedFinalPrice,
                parsedFinalVatAmount: parsedUnitaryVatRate
                  ? getUnitaryVatAmount(op, parsedUnitaryVatRate) *
                    getQuantity(op)
                  : 0,
                parsedTotalNetPrice,
                parsedTotalVatAmount,
                parsedTotalPriceVatIncluded: parsedTotalPrice,
              }
            : {
                parsedUnitaryVatRate: 'hidden',
                parsedUnitaryVatAmount: 'hidden',
                parsedUnitaryPrice: 'hidden',
                parsedFinalPrice: 'hidden',
                parsedFinalVatAmount: 'hidden',
                parsedTotalNetPrice: 'hidden',
                parsedTotalVatAmount: 'hidden',
                parsedTotalPriceVatIncluded: 'hidden',
              }),
        };
      });

      const netTotal = buyOperationsItems.reduce(
        (res, curr) => (res += getUnitaryPrice(curr) * getQuantity(curr)),
        0
      );
      const vatTotal = buyOperationsItems.reduce(
        (res, curr) => (res += curr.parsedFinalVatAmount),
        0
      );

      return {
        ...item,
        paymentTypeLabel: paymentTypeFormat,
        paidFee: decodeDbNumber(paidFee),
        totalFee: Number(totalFee),
        netTotal: Number(netTotal),
        vatAmount: Number(vatTotal),
        buyOperations: {
          items: buyOperationsItems,
        },
      };
    });

    setPurchases(formattedPurchases);
  };

  const getServiceType = (item) => {
    if (item.bannerArea !== null) return i18n.servicesName.bannerArea;
    if (item.symposium !== null) return i18n.servicesName.symposium;
    if (item.publicPage !== null) return i18n.servicesName.publicPage;
    if (item.breakoutRoom !== null) return i18n.servicesName.breakoutRoom;
    if (item.physicalStandSpace !== null)
      return i18n.servicesName.physicalStandSpace;
    if (item.physicalStandSpaceItems !== null)
      return i18n.servicesName.physicalStandSpaceItems;
    if (item.sponsorVirtualStand !== null)
      return i18n.servicesName.virtualStand;
    if (item.sponsorStaffPurchase !== null)
      return i18n.servicesName.sponsorStaff;
    if (item.sponsorListPurchase !== null) return i18n.servicesName.sponsorList;
    if (item.symposiumServicesPurchase !== null)
      return i18n.servicesName.symposiumServices;
    if (item.sponsorPackage !== null) return item.name;
    if (item.otherSponsorizationsBookingItem !== null)
      return i18n.servicesName.otherSponsorizations;
  };

  const getInstance = (item) => {
    if (item.bannerArea !== null) return item?.bannerArea?.name;
    if (item.symposium !== null) return item?.symposium?.agendaSession?.name;
    if (item.publicPage !== null) return '-';
    if (item.breakoutRoom !== null) return '-';
    if (item.physicalStandSpace !== null) return item?.physicalStandSpace?.name;
    if (item.physicalStandSpaceItems !== null)
      return item?.physicalStandSpaceItems?.item?.name;
    if (item.sponsorVirtualStand !== null)
      return item?.sponsorVirtualStand?.virtualStand?.name;
    if (item.sponsorStaffPurchase !== null) return '-';
    if (item.sponsorPackage !== null) return '-';
    if (item.otherSponsorizationsBookingItem !== null)
      return item?.otherSponsorizationsBookingItem?.item?.name;
    if (item.symposiumServicesPurchase !== null)
      return item?.symposiumServicesPurchase?.symposiumServicesItem?.name;
  };

  const getUnitaryVatRate = (item) => {
    if (item?.sponsorPackage)
      return utilities.safeNum(
        item.sponsorPackage.services.items.reduce(
          (prev, curr) => prev + utilities.decodeDbNumber(curr.vatRate),
          0
        )
      );
    else return utilities.decodeDbNumber(item.unitaryVatRate) || 0;
  };

  const getQuantity = (item) => {
    if (item.physicalStandSpaceItems !== null)
      return (
        Number(item?.physicalStandSpaceItems?.quantity || 1) *
        Number(item?.physicalStandSpaceItems?.amountPerQuantity || 1)
      );
    else if (item.otherSponsorizationsBookingItem !== null)
      return (
        Number(item?.otherSponsorizationsBookingItem?.quantity || 1) *
        Number(item?.otherSponsorizationsBookingItem?.amountPerQuantity || 1)
      );
    else if (item.sponsorStaffPurchase !== null)
      return Number(item?.sponsorStaffPurchase?.quantity || 1);
    else if (item.symposiumServicesPurchase !== null)
      return Number(item?.symposiumServicesPurchase?.quantity || 1);
    else if (item.sponsorListPurchase !== null)
      return Number(item?.sponsorListPurchase?.quantity || 1);
    else if (item?.sponsorPackage)
      return Number(item?.sponsorPackage?.quantity || 1);
    else return 1;
  };

  const getUnitaryPrice = (item) => {
    if (item?.sponsorPackage)
      return utilities.safeNum(
        item.sponsorPackage.services.items.reduce(
          (prev, curr) =>
            prev + utilities.decodeDbNumber(curr.netPrice) * curr.quantity,
          0
        )
      );
    else return utilities.decodeDbNumber(item.unitaryPrice);
  };

  const getUnitaryVatAmount = (item, vatRate) => {
    if (item?.sponsorPackage) {
      const vatAmountOfPackage = utilities.safeNum(
        item.sponsorPackage.services.items.reduce((prev, curr) => {
          const priceOfPackage =
            utilities.decodeDbNumber(curr.netPrice) * curr.quantity;
          return prev + utilities.amountOfVat(priceOfPackage, vatRate);
        }, 0)
      );
      return vatAmountOfPackage;
    } else {
      const vatAmount = utilities.amountOfVat(
        utilities.decodeDbNumber(item.unitaryPrice),
        vatRate
      );
      return vatAmount;
    }
  };

  return (
    <MainContainer>
      <InnerContainer grow bottomSpacing>
        <div style={{ display: 'flex-root', marginLeft: 15, marginTop: 20 }}>
          <Tooltip title={i18n.page.back.tooltip.label}>
            <AimIconAndTextButton
              variant="text"
              text={i18n.page.back.button.label}
              onClick={() => history.goBack()}
            >
              <ArrowBack />
            </AimIconAndTextButton>
          </Tooltip>
          <AimTitleAndButtons title={i18n.page.title.label} />
        </div>
        {/* <AimPurchasesTable purchases={purchases} i18n={i18nTable} /> */}
        <AimPurchasesDatagrid
          purchases={purchases}
          i18n={i18nTable}
          intl={intl}
          onUpdateVisibleAndCheckedRows={(data) => {
            datagridFilteredRowsRef.current = data.filteredRows;
          }}
          columnsToHide={['unitaryCost', 'totalCost']}
        />
      </InnerContainer>
    </MainContainer>
  );
};

export default MyPurchases;
