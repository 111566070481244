import { aws, appState } from '@aim/common';
const { API } = aws;

const showLoader = () => appState.isLoader.next(true);

const hideLoader = () => appState.isLoader.next(false);

const listBillingDocsQuery = /* GraphQL */ `
  query ListBillingDocs($id: ID!) {
    getEvent(id: $id) {
      billingDocs {
        items {
          id
          docNumber
          billingCustomer
          isPublished
          type
          billingDocSponsorId
          amount
          sponsor {
            id
          }
          document {
            id
            originalName
            extension
            isMandatory
            size
          }
          billingFlow {
            id
            flowNumber
            docType
          }
        }
      }
    }
  }
`;

export const listBillingDocs = (id, useLoader = true) =>
  new Promise((resolve, reject) => {
    useLoader && showLoader();
    API.graphql({
      query: listBillingDocsQuery,
      variables: { id },
    })
      .then((response) => resolve(response.data.getEvent))
      .catch((e) => {
        console.error('get-sponsor-invoices', e);
        reject();
      })
      .finally(() => useLoader && hideLoader());
  });
