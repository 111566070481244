import React, { useState, useEffect } from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import TwitterIcon from '@material-ui/icons/Twitter';
import Grid from '@material-ui/core/Grid';

import { appState, constants } from '@aim/common';

import {
  styled,
  CustomIntl,
  AimTypography,
  useAimMediaQuery,
} from '@aim/components';

import translation from './socialWall/translation';
import {
  getSocialWallPosts,
  getEventTwitterTagsByEventId,
} from './socialWall/gqlHelper';
import { getEventConfigurationPageTitle } from '../shared/utilities';

const PageContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'center',
  // alignItems: 'center',
  width: '100%',
  padding: '0px 30px',
});
const Container = styled('div')(({ isMobileOnly }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  width: isMobileOnly ? '100%' : '70%',
}));

const SocialWall = () => {
  const { eventId } = useParams();
  const intl = CustomIntl(useIntl());
  const i18n = translation.socialWall(intl);
  const { isMobileOnly } = useAimMediaQuery();

  const [twitterTags, setTwitterTags] = useState([]);

  const [socialWallPosts, setSocialWallPosts] = useState([]);
  const [displaySocialWallPosts, setDisplaySocialWallPosts] = useState([]);
  const [loadedPosts, setLoadedPosts] = useState(0);

  const [nextToken, setNextToken] = useState(null); //null: initial state, false: all posts have been retrieved
  const incBy = 5000;

  // State
  const [eventConfiguration] = useState(appState.eventConfiguration.getValue());
  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => {
    const menuItemLabel = getEventConfigurationPageTitle(
      eventConfiguration?.eventGenericMenuItems?.items || [],
      intl,
      constants.EventGenericMenuItems.SOCIAL_WALL.key
    );
    setPageTitle(menuItemLabel);
  }, [eventConfiguration]);

  const fetchPosts = (input) => {
    getSocialWallPosts(input).then(({ data }) => {
      setNextToken(
        data.listSocialWallItemsByStatus?.nextToken
          ? data.listSocialWallItemsByStatus?.nextToken
          : false
      );
      var nextSocialWallPosts = [
        ...socialWallPosts,
        ...data.listSocialWallItemsByStatus?.items.map((el) => ({
          createdAt: el.createdAt,
          postId: el.postId,
          creationDateTime: new Date(el.creationDateTime),
        })),
      ].sort((a, b) => b.creationDateTime - a.creationDateTime);
      console.log(nextSocialWallPosts);
      setSocialWallPosts(nextSocialWallPosts);
      setDisplaySocialWallPosts(nextSocialWallPosts?.slice(0, loadedPosts + 3));
    });
  };

  const useScrollPosition = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
      const updatePosition = () => {
        setScrollPosition(window.pageYOffset);
      };
      window.addEventListener('scroll', updatePosition);
      updatePosition();
      return () => window.removeEventListener('scroll', updatePosition);
    }, []);

    return scrollPosition;
  };

  const scrollPosition = useScrollPosition();

  useEffect(() => {
    if (
      window.innerHeight + scrollPosition >= document.body.scrollHeight &&
      displaySocialWallPosts.length === loadedPosts
    ) {
      if (
        displaySocialWallPosts.length === socialWallPosts.length &&
        nextToken !== false
      ) {
        fetchPosts({
          limit: incBy,
          nextToken: nextToken ? nextToken : undefined,
          socialWallItemEventId: eventId,
        });
      } else if (displaySocialWallPosts.length < socialWallPosts.length) {
        setDisplaySocialWallPosts(socialWallPosts?.slice(0, loadedPosts + 3));
      }
    }
  }, [scrollPosition]);

  useEffect(() => {
    fetchPosts({
      limit: incBy,
      nextToken: nextToken ? nextToken : undefined,
      socialWallItemEventId: eventId,
    });
    getEventTwitterTagsByEventId({
      id: eventId,
    }).then((x) => {
      setTwitterTags(x.getEvent.twitterSocialWallTags);
    });
  }, []);

  return (
    <Container isMobileOnly={isMobileOnly}>
      <AimTypography variant="h1" margin={'8px 0px 8px 30px'}>
        {pageTitle}
      </AimTypography>
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <PageContent>
          {socialWallPosts.length > 0 ? (
            <div
              id="twitterContainer"
              style={{
                flex: 1,
                // maxHeight: 1000,
                // overflow: 'auto',
                marginBottom: 20,
                padding: '20px 20px 20px 20px',
                alignItems: 'center',
              }}
              // onScroll={handleScroll}
            >
              <Grid container style={{ marginBottom: 20 }}>
                <Grid item xs={6}>
                  <p style={{ fontWeight: 'bold' }}>JOIN THE CONVERSATION</p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ justifyContent: 'flex-end', display: 'flex' }}
                >
                  <TwitterIcon style={{ color: '#03a8f4' }} />
                </Grid>
                <Grid item xs={12}>
                  <p>
                    Follow our community on twitter and share posts with the
                    hashtags {twitterTags.join(' ')}
                  </p>
                </Grid>
              </Grid>
              <Grid container>
                {displaySocialWallPosts.map((x, index) => {
                  return (
                    <Grid item md={6} xs={12} key={index}>
                      <div
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <TwitterTweetEmbed
                          onLoad={() => {
                            setLoadedPosts((loadedPosts) => loadedPosts + 1);
                          }}
                          options={{
                            width: isMobileOnly ? 290 : 550,
                            align: 'center',
                            conversation: 'none',
                          }}
                          tweetId={x.postId}
                        />
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          ) : (
            <AimTypography>{i18n.page.noPosts}</AimTypography>
          )}
        </PageContent>
      </div>
    </Container>
  );
};

export default SocialWall;
